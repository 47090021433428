<template>
  <div class="mobile-detail">
    <div class="mobile-detail-container" :style="buttons && buttons.length > 0 ? '' : 'margin-bottom:0'">
      <cube-scroll-nav ref="nav" current="基本信息" v-if="detail">
        <cube-scroll-nav-panel key="基本信息" label="基本信息">
          <inputItem title="计划单号" :initialContent="detail.code" :disabled="true"></inputItem>
          <inputItem title="业务代表" :initialContent="detail.user_name" :disabled="true"></inputItem>
          <inputItem title="时间范围" :initialContent="formatDate(detail.s_time) + ' ~ ' + formatDate(detail.e_time)"
            :disabled="disabled" name="time" type="segment" :contentChanged="contentChanged"></inputItem>
          <inputItem title="计划主题" v-model="detail.title" :disabled="disabled"></inputItem>
        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="计划明细" label="计划明细">
          <div class="mobile-detail-list">
            <div class="list-item" v-for="(item, index) in items" :key="item.id">
              <div class="item-header">
                <div class="item-index">{{ item.type == 1 ? "临时" : "计划" }}{{ index + 1 }}</div>
                <img class="item-delete" src="@/static/images/icon_delete.png" alt @click="deleteItem(index)"
                  v-if="!disabled || !item.id" />
              </div>
              <inputItem title="计划类型" type="select" :options="categorys"
                :initialContent="getDictText(categorys, item.category)" :disabled="disabled && item.state != 9"
                name="category" :index="String(index)" :contentChanged="itemChanged"></inputItem>
              <inputItem title="相关项目" :initialContent="item.project_name" :disabled="disabled && item.state != 9"
                type="others" ref="project_name" :index="String(index)" :otherClicked="clickProject"></inputItem>
              <inputItem title="计划日期" :initialContent="formatDate(item.time)" :disabled="disabled && item.state != 9"
                type="date" name="time" :index="String(index)" :contentChanged="itemChanged"></inputItem>
              <inputItem title="计划内容" v-if="item.category == 1" v-model="item.cause" :disabled="disabled && item.state != 9"
                type="textarea"></inputItem>
              <inputItem title="计划状态" v-if="item.category == 1 && detail.state > 3 && item.state != 9"
                :initialContent="getDictText(itemState, item.state)" :disabled="true"></inputItem>

              <inputItem title="拜访客户" v-show="item.category == 0" :initialContent="item.customer_name"
                :disabled="disabled && item.state != 9" type="others" ref="customer_name" :index="String(index)"
                :otherClicked="clickCustomer"></inputItem>
              <inputItem title="拜访事由" v-if="item.category == 0" v-model="item.cause" :disabled="disabled && item.state != 9"
                type="textarea"></inputItem>
              <inputItem title="拜访状态" v-if="item.category == 0 && detail.state > 3 && item.state != 9"
                :initialContent="getDictText(itemState, item.state)" :disabled="true"></inputItem>

              <inputItem title="到达时间" v-if="item.category == 0 && (item.state == 1 || item.state == 2)"
                :initialContent="formatDate(item.fact_time, 'yyyy-MM-dd hh:mm')" :disabled="true"></inputItem>
              <inputItem title="到达位置" v-if="item.category == 0 && (item.state == 1 || item.state == 2)"
                :initialContent="item.fact_address" :disabled="false" type="others" :index="String(index)"
                :otherClicked="clickAddress"></inputItem>
              <inputItem title="取消时间" :initialContent="formatDate(item.finish_time, 'yyyy-MM-dd hh:mm')" :disabled="true"
                v-if="item.state == 3"></inputItem>
              <inputItem title="取消原因" v-model="item.finish_memo" :disabled="true" v-if="item.state == 3" type="textarea">
              </inputItem>
              <inputItem title="完成时间" :initialContent="formatDate(item.finish_time, 'yyyy-MM-dd hh:mm')" :disabled="true"
                v-if="item.state == 2"></inputItem>
              <inputItem title="完成备注" v-model="item.finish_memo" :disabled="true" v-if="item.state == 2" type="textarea">
              </inputItem>
              <comment :id="item.id" order_name="工作计划" v-if="detail.state > 3 && item.state != 9"></comment>
              <div class="item-button-box" v-if="item.type == 1 && item.state == 9">
                <div class="item-button button-bg1" @click="saveItem(index)">保存临时计划</div>
              </div>
            </div>
            <div class="item-add" v-if="!disabled" alt @click="addItem"> 添加计划 </div>
            <div class="item-add" v-if="allowEdit && disabled && detail.state > 3" alt @click="addFinishItem"> 添加临时计划
            </div>
            <Modal v-model="showFinishMemo" :title="newFinishTitle" @on-ok='finishVisitPlanItem'>
              <textarea v-model="newFinishMemo" class="full-line default-font-color"
                style="height:220px;border: 0;background-color: #EFEFEF;padding: 10px;resize: none;border-radius:5px;"></textarea>
            </Modal>
          </div>
        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="总结心得" label="总结心得" v-if="detail.state > 3">
          <div class="mobile-detail-list">
            <div class="list-item" v-for="(item, index) in reviews" :key="index">
              <div class="item-header">
                <div class="item-index">总结{{ index + 1 }}</div>
                <img class="item-delete" src="@/static/images/icon_delete.png" alt
                  @click="deleteReview(index, item.id)" />
              </div>
              <div class="item-memo">{{ formatDate(item.time, 'yyyy-MM-dd hh:mm') }}</div>
              <div class="item-memo">{{ item.memo }}</div>
              <comment :id="item.id" order_name="工作计划"></comment>
            </div>
            <Modal v-model="showComment" title="请输入总结心得" @on-ok='confirmReview'>
              <textarea v-model="newComment" class="full-line default-font-color"
                style="height:300px;border: 0;background-color: #EFEFEF;padding: 10px;resize: none;border-radius:5px;"></textarea>
            </Modal>
            <div class="item-add" v-if="disabled" alt @click="addReview"> 添加总结 </div>
          </div>
        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="审批流程" label="审批流程" v-if="flows && flows.flowLogs && flows.flowLogs.length > 0">
          <div class="mobile-detail-step">
            <Steps :current="flows.step" :status="flows.status" direction="vertical">
              <Step v-for="(item, index) in flows.flowLogs" :key="index" :title="item.userName"
                :content="formatDate(item.time, 'yyyy-MM-dd hh:mm') + (item.opinion ? ' - ' + item.opinion : '')">
              </Step>
            </Steps>
          </div>
        </cube-scroll-nav-panel>
      </cube-scroll-nav>
    </div>
    <buttonGroup v-if="buttons && buttons.length > 0" :buttons="buttons" @button-click="buttonClick"></buttonGroup>
    <optionDrawer ref="optionDrawerProject" v-if="showOptionsProject" :onBgClicked="onBgClickedProject"
      :onSearch="onSearchProject" :items="projects" @optionClicked="optionClickedProject"> </optionDrawer>
    <optionDrawer ref="optionDrawerCustomer" v-if="showOptionsCustomer" :onBgClicked="onBgClickedCustomer"
      :onSearch="onSearchCustomer" :items="customers" @optionClicked="optionClickedCustomer"> </optionDrawer>
  </div>
</template>

<script>
import { formatDate } from "@/utils/format.js";
import { wgs84ToGcj02 } from "@/utils/wgs84.js";
import { getDictText, visitPlanCategory, visitPlanPriority, visitPlanState } from "@/utils/dict.js";
import { GetVisitPlan, SaveVisitPlan, AuditVisitPlan, CancelVisitPlan, FinishVisitPlanItem, AddVisitPlanItem, AddVisitPlanReview, DeleteVisitPlanReview } from "@/api";
import { loadDetailAction, saveAction, auditAction, cancelAction, submitAction, loadCustomerOptionAction, loadProjectOptionAction } from "@/utils/action.js";

export default {
  data: function () {
    return {
      id: "",
      detail: null,
      items: [],
      reviews: [],
      flows: [],
      buttons: [],
      categorys: visitPlanCategory,
      prioritys: visitPlanPriority,
      itemState: visitPlanState,
      customers: [],
      showOptionsCustomer: false,
      projects: [],
      showOptionsProject: false,
      allowEdit: false,
      disabled: false,
      itemIndex: -1,
      longitude: "0",
      latitude: "0",
      showComment: false,
      newComment: "",
      showFinishMemo: false,
      newFinishTitle: "",
      newFinishMemo: "",
      newFinishId: "",
      newFinishState: false
    };
  },
  methods: {
    formatDate: formatDate,
    getDictText: getDictText,
    addReview: function () {
      this.showComment = true;
    },
    confirmReview: function () {
      submitAction(this, AddVisitPlanReview, { review: { pid: this.detail.id, memo: this.newComment } }, () => { this.dataRequest(); });
    },
    deleteReview: function (index, id) {
      submitAction(this, DeleteVisitPlanReview, { id: id }, () => { this.reviews.splice(index, 1); }, null, true, "确认删除吗？");
    },
    addItem: function () {
      this.items.push({ id: Math.random(), category: 0, priority: 0, type: 0, state: 0 });
    },
    addFinishItem: function () {
      this.items.push({ category: 0, priority: 0, type: 1, state: 9, time: new Date(), fact_longitude: this.longitude, fact_latitude: this.latitude });
    },
    deleteItem: function (index) {
      this.items.splice(index, 1);
    },
    saveItem: function (index) {
      var item = this.items[index];
      submitAction(this, AddVisitPlanItem, { item: { category: item.category, priority: item.priority, pid: this.detail.id, type: 1, time: item.time, fact_longitude: item.fact_longitude, fact_latitude: item.fact_latitude, customer_id: item.customer_id, customer_name: item.customer_name, cause: item.cause, finish_memo: item.finish_memo, project_id: item.project_id, project_name: item.project_name } }, () => { this.dataRequest(); }, null, true, "保存后不可删除，是否继续？");
    },
    contentChanged: function (name, value) {
      switch (name) {
        case "time":
          this.detail.s_time = value.split(",")[0];
          this.detail.e_time = value.split(",")[1];
          break;
      }
    },
    itemChanged: function (name, index, value) {
      switch (name) {
        case "time":
          this.items[Number(index)].time = value;
          break;
        case "category":
          this.items[Number(index)].category = value;
          break;
        case "priority":
          this.items[Number(index)].priority = value;
          break;
      }
    },
    clickAddress: function (index) {
      var item = this.items[index];
      if (item.fact_longitude && item.fact_latitude) {
        window.open("https://nav.kdainfo.com/openlocationoa.html?lng=" + item.fact_longitude + "&lat=" + item.fact_latitude, "_blank");
      }
    },
    onSearchProject: function (value) {
      loadProjectOptionAction(this, value, (data) => { this.projects = data; });
    },
    onBgClickedProject: function () {
      this.showOptionsProject = false;
    },
    optionClickedProject: function (item) {
      this.items[this.itemIndex].project_id = item.id;
      this.items[this.itemIndex].project_name = item.name;
      this.items[this.itemIndex].customer_id = item.customer_id;
      this.items[this.itemIndex].customer_name = item.customer_name;
      this.$refs.project_name[this.itemIndex].setContent(item.name);
      this.$refs.customer_name[this.itemIndex].setContent(item.customer_name);
      this.showOptionsProject = false;
    },
    clickProject: function (index) {
      this.itemIndex = Number(index);
      this.showOptionsProject = true;
      this.$nextTick(() => { setTimeout(() => { this.$refs.optionDrawerProject.show(); }, 100); });
    },
    onSearchCustomer: function (value) {
      loadCustomerOptionAction(this, value, (data) => { this.customers = data; });
    },
    onBgClickedCustomer: function () {
      this.showOptionsCustomer = false;
    },
    optionClickedCustomer: function (item) {
      this.items[this.itemIndex].customer_id = item.id;
      this.items[this.itemIndex].customer_name = item.name;
      this.$refs.customer_name[this.itemIndex].setContent(item.name);
      this.showOptionsCustomer = false;
    },
    clickCustomer: function (index) {
      this.itemIndex = Number(index);
      this.showOptionsCustomer = true;
      this.$nextTick(() => { setTimeout(() => { this.$refs.optionDrawerCustomer.show(); }, 100); });
    },
    buttonClick: function (title) {
      switch (title) {
        case "保存草稿":
          this.saveVisitPlan(1);
          break;
        case "提交审核":
          this.saveVisitPlan(2);
          break;
        case "审核通过":
          this.auditVisitPlan(true, "同意");
          break;
        case "审核拒绝":
          this.auditVisitPlan(false, "");
          break;
        case "撤回":
          this.cancelVisitPlan();
          break;
      }
    },
    saveVisitPlan: function (state) {
      this.detail.state = state;
      saveAction(this, SaveVisitPlan, { model: this.detail, items: this.items }, () => { this.$router.push('/mobile/office/visitplanlist'); }, () => { this.detail.state = 1; });
    },
    auditVisitPlan: function (pass, memo) {
      auditAction(this, AuditVisitPlan, this.id, pass, memo);
    },
    cancelVisitPlan: function () {
      cancelAction(this, CancelVisitPlan, this.id);
    },
    finishVisitPlanItem: function () {
      saveAction(this, FinishVisitPlanItem, { id: this.newFinishId, finish: this.newFinishState, memo: this.newFinishMemo, longitude: this.longitude, latitude: this.latitude }, () => { this.dataRequest(); });
    },
    dataRequest: function () {
      loadDetailAction(this, GetVisitPlan, { id: this.id }, (data) => {
        this.detail = data.list[0].model;
        this.items = data.list[0].items;
        this.reviews = data.list[0].reviews;
        this.flows = data.list[0].flows;
        this.buttons = data.list[0].buttons;
        this.allowEdit = data.list[0].allowEdit;
        this.disabled = !this.buttons || this.buttons.indexOf("保存草稿") < 0;
        this.$nextTick(() => { this.$refs.nav.refresh(); });
      });
    },
    setPosition: function (position) {
      var transformLnglat = wgs84ToGcj02(position.coords.longitude, position.coords.latitude);
      this.longitude = transformLnglat[0];
      this.latitude = transformLnglat[1];
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.dataRequest();
    }
  },
};
</script>